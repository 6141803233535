const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_identity_pool_id:
    "us-east-1:df13f83c-fcb9-4a77-a0da-d663cfaca974",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_2heocbJNt",
  aws_user_pools_web_client_id: "44qt7su4qt0dt13f0dlqat6l6k",
  oauth: {
    domain: "casadosaber-dev.auth.us-east-1.amazoncognito.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "https://signin.casadosaber.testing.sambatech.dev/",
    redirectSignOut: "https://signin.casadosaber.testing.sambatech.dev/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
};

export default awsmobile;